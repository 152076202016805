<template>
  <div class="center examplex">
    <table>
      <thead>
        <tr>
          <th>Username</th>
          <th>Email</th>
          <th>bidAmount</th>
          <th>Time</th>
          <th v-if="editButtonShow">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in historyData" :key="item._id">
          <td>{{ item.userId.username }}</td>
          <td>{{ item.userId.email }}</td>
          <td>
            <p class="editedAmountContainer">
              ${{ new Intl.NumberFormat("en-AU").format(item.bidAmount) }}
              <span class="red" v-if="item.isEditedByAdmin">*</span>
            </p>
          </td>

          <td>{{ getFormatedDate(item.createdAt) }}</td>
          <td v-if="editButtonShow">
            <vs-button
              color="danger"
              type="filled"
              shadow
              v-if="editButtonShow && index === 0"
              @click="handleEditBidAmountPopup(item._id, item.bidAmount)"
            >
              Edit
            </vs-button>
          </td>
        </tr>
      </tbody>
    </table>
    <span class="red" v-if="hasEditedData"
      >* Bidding error corrected by Caroo admin</span
    ><br />
    <span class="mr-2"
      >{{ currentPage * dataTableParams.limit - (dataTableParams.limit - 1) }}
      -
      {{
        totalDocs - currentPage * dataTableParams.limit > 0
          ? currentPage * dataTableParams.limit
          : totalDocs
      }}
      of {{ totalDocs }}</span
    >
    <vs-pagination
      :total="totalPage"
      v-model="dataTableParams.page"
    ></vs-pagination>
    <vs-popup
      class="holamundo"
      title="Edit Bid Amount"
      :active.sync="editBidAmountPopupActive"
    >
      <template>
        <vs-input
          val-icon-success="done"
          val-icon-danger="error"
          data-vv-validate-on="blur"
          data-vv-as="first name"
          name="bidAmount"
          label-placeholder="Bid Amount"
          placeholder="Bid Amount"
          v-model="bidAmount"
          class="w-full mt-6"
        />
        <span class="text-danger text-sm">{{ saveError }}</span
        ><br />
        <vs-button
          color="danger"
          type="filled"
          shadow
          @click="handlePasswordPopup"
        >
          Save
        </vs-button>
      </template>
    </vs-popup>
    <vs-popup
      class="holamundo"
      title="Enter password"
      :active.sync="passwordPopUpActive"
    >
      <template>
        <vs-input
          data-vv-validate-on="blur"
          v-validate="'required'"
          name="password"
          label-placeholder="Admin Password"
          placeholder="Admin Password"
          icon-no-border
          icon-pack="feather"
          v-model="password"
          class="w-full"
          :icon="passwordIcon"
          :type="passwordType"
          @click.native="showHidePassword($event)"
        />
        <span class="text-danger text-sm">{{ saveError }}</span
        ><br />
        <vs-button
          color="danger"
          type="filled"
          shadow
          @click="checkAdminPassword"
        >
          Next
        </vs-button>
      </template>
    </vs-popup>
  </div>
</template>
<script>
import { auctionStatus } from "../../components/constants/enums";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        page: 1,
        limit: 10,
      },
      biddingHistoryTablePopupActive: false,
      editBidAmountPopupActive: false,
      historyData: [],
      toEditBidId: "",
      saveError: "",
      bidAmount: 0,
      hasEditedData: false,
      editButtonShow: true,
      password: "",
      passwordPopUpActive: false,
      passwordType: "password",
      passwordIcon: "icon icon-eye-off",
    };
  },
  props: {
    auctionId: {
      type: String,
      required: true,
    },
    auctionStatus: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.getBiddingHistoryData();
  },
  methods: {
    ...mapActions("admin", [
      "handleCarBiddingHistory",
      "handleBidAmountChange",
      "handleCheckPassword",
    ]),
    showHidePassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.showPassword = !this.showPassword;
        this.passwordType === "password"
          ? (this.passwordType = "text")
          : (this.passwordType = "password");
        this.passwordIcon === "icon icon-eye-off"
          ? (this.passwordIcon = "icon icon-eye")
          : (this.passwordIcon = "icon icon-eye-off");
      }
      return true;
    },
    getBiddingHistoryData() {
      let self = this;
      this.dataTableParams.id = this.auctionId;
      if (
        this.auctionStatus === auctionStatus.COMPLETED_RESERVED_NOT_MET ||
        this.auctionStatus === auctionStatus.COMPLETED_SOLD
      ) {
        this.editButtonShow = false;
      }
      this.handleCarBiddingHistory(this.dataTableParams).then((res) => {
        this.historyData = res.data.data.docs;
        this.hasEditedData = res.data.data.docs.some(
          (object) => object.isEditedByAdmin === true
        );
        self.historyData = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
        self.currentPage = self.page;
      });
    },
    handleEditBidAmountPopup(bidId, bidAmount) {
      this.biddingHistoryTablePopupActive = false;
      this.editBidAmountPopupActive = true;
      this.bidAmount = bidAmount;
      this.toEditBidId = bidId;
    },
    handlePasswordPopup() {
      if (this.bidAmount.match(/^[0-9]+$/) != null) {
        this.passwordPopUpActive = true;
        this.editBidAmountPopupActive = false;
      }
    },
    checkAdminPassword() {
      const data = {
        password: this.password,
      };
      this.handleCheckPassword(data)
        .then((res) => {
          this.$vs.loading.close();
          if (res.data.data) {
            this.passwordPopUpActive = false;
            this.password = "";
            this.editBidAmountPopupActive = false;
            let obj = this.historyData.find((o) => o._id === this.toEditBidId);
            if (obj) {
              obj.bidAmount = this.bidAmount;
              obj.isEditedByAdmin = true;
            }
            this.handleSaveNewBidAmount();
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error",
            text: "Incorrect Password",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    handleSaveNewBidAmount() {
      if (this.bidAmount.match(/^[0-9]+$/) != null) {
        this.saveError = "";
        let auctionId = this.$route.params.id;
        const data = {
          id: this.toEditBidId,
          newBidAmount: this.bidAmount,
          auctionId,
        };
        this.handleBidAmountChange(data).then((res) => {
          this.$vs.loading.close();
          if (res.data.data) {
            this.editBidAmountPopupActive = false;
            this.biddingHistoryTablePopupActive = true;
            this.hasEditedData = true;
            this.$vs.notify({
              title: "Car Updated",
              text: res.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: res.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        });
      } else {
        this.saveError = "Bid Amount can only be numbers";
      }
    },
    openBidHistoryPopUp() {
      this.openBidHistory = true;
    },
    getFormatedDate(date) {
      let temp = new Date(date);
      let day = temp.getDate();
      let month = temp.getMonth() + 1;
      let year = temp.getFullYear();
      let dateOnly = year + "-" + month + "-" + day;
      let hour = temp.getHours();
      let minutes = temp.getMinutes();
      let amPm = hour < 12 ? "AM" : "PM";
      hour = hour % 12;
      hour = hour ? hour : 12;
      let time = `${hour}:${minutes < 10 ? "0" + minutes : minutes} ${amPm}`;
      return dateOnly + " " + time;
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getBiddingHistoryData();
      }
    },
  },
};
</script>
<style>
tr:nth-child(even) td {
  background-color: #f2f2f2;
}

table {
  width: 100%;
}

td {
  padding-left: 10px;
  height: 50px;
}

.red {
  color: red;
}

.editedAmountContainer {
  display: inline-block;
}
</style>
