 
<template>
  <vx-card slot="no-body" class="tabs-container px-6 pt-6" v-if="isRender">
    <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
      <vs-tab label="Basic" icon-pack="feather" icon="icon-flag">
        <div class="tab-text">
        <basic-details class="mt-4" :data="auctionDetail" :handleConfirmation="confirmationStatus"></basic-details>
        </div>
      </vs-tab>
      <vs-tab label="Photos" icon-pack="feather" icon="icon-image">
        <div class="tab-text">
          <image-carousel class="mt-4" :images="auctionDetail.car.images" />
        </div>
      </vs-tab>
      <vs-tab label="Bidding History" icon-pack="feather" icon="icon-image">
        <div class="tab-text">
          <bidding-history class="mt-4" :auctionId="auctionDetail._id" :auctionStatus="auctionDetail.status" />
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import ImageCarousel from "../car-list/ImageCarousel.vue";
import BasicDetails from "./basicDetails.vue";
import BiddingHistory from "./biddingHistory.vue";


export default {
  name: "CarDetails",
  components: {
    ImageCarousel,
    BasicDetails,
    BiddingHistory
  },
  data() {
    return {
      auctionDetail: {
        images: [],
        userId: {},
      },
      isRender: false,
      activeTab: 0,
      back() {
        this.$router.push({ name: "Vehicles" });
      },
    };
  },

  methods: {
    ...mapActions("admin", ["carDetails","handleNewCarListingStatus","auctionDetails"]),
    getAuctionDetail(id) {
        let self = this;
      this.auctionDetails(id).then((res) => {
        this.auctionDetail = res.data.data;
        this.isRender = true;
      });
    },
    confirmationStatus(isRejected) {
      let data = {
            id: this.carDetail._id,
            isRejected,
      };
          this.handleNewCarListingStatus(data).then((res) => {
            if (res.data.data) {
              this.$vs.notify({
                title: "New car status changed",
                text: res.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
              this.$router.go(-1);
            } else {
              this.$vs.notify({
                title: "Error",
                text: res.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });
            }
          });
    }
  },

  created() {
    if (this.$route.query.activeTab) {
      this.activeTab = this.$route.query.activeTab
    }
    let auctionId = this.$route.params.id;
    this.getAuctionDetail(auctionId);
  },
};
</script> 
<style >
.ql-editor {
  min-height: 200px;
}
</style> 