<template>
  <vs-row>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="licencePlate">Lot Number</label>
        <p id="licencePlate">{{ data.lotNumber ? data.lotNumber : "N/A" }}</p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="make">Auction Status</label>
        <p id="make">
          {{ data.status ? checkAuctionStatus(data.status) : "N/A" }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="year">Created</label>
        <p id="year">
          {{ data.createdAt ? getFormatedDate(data.createdAt) : "N/A" }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="year">Approved</label>
        <p id="year">
          {{ data.approvalTime ? getFormatedDate(data.approvalTime) : "N/A" }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="year">Auction Start Date</label>
        <p id="year">
          {{
            data.auctionEndDate ? getFormatedDate(data.auctionStartDate) : "N/A"
          }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="year">Auction End Date</label>
        <p id="year">
          {{
            data.auctionEndDate ? getFormatedDate(data.auctionEndDate) : "N/A"
          }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="year">Auction Period</label>
        <p id="year">
          {{ getDaysDifference(data.auctionEndDate, data.auctionStartDate) }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="model">Car Details</label>
        <p id="model">
          {{
            data.car
              ? data.car.make + " " + data.car.model + " " + data.car.year
              : "N/A"
          }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="year">Bids</label>
        <p id="year">{{ data.bidCount ? data.bidCount : "0" }}</p>
      </div>
    </vs-col>

    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="badge">Reserver Price</label>
        <p id="badge">
          {{
            data.reservePrice
              ? new Intl.NumberFormat("en-AU").format(data.reservePrice)
              : "N/A"
          }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="series">Reserve Met</label>
        <p id="series">
          {{
            data.reservePrice
              ? checkReserveMet(data.lastBid, data.reservePrice)
              : "N/A"
          }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="transmission">Auction Outcome</label>
        <p id="transmission">
          {{ data.status ? formatOutcome(data.status) : "N/A" }}
        </p>
      </div>
    </vs-col>
  </vs-row>
</template>

<script>
import moment from "moment";
import { auctionStatus } from "../../components/constants/enums";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    handleConfirmation: { type: Function },
  },
  filters: {
    // Filter definitions
    capitalize(string) {
      if (string) {
        return string.charAt(0).toString().toUpperCase() + string.slice(1);
      } else {
        return "Refer Seller";
      }
    },
  },
  methods: {
    moment(date) {
      return moment(date);
    },
    getFormatedDate(date) {
      let temp = new Date(date);
      let day = temp.getDate();
      let month = temp.getMonth() + 1;
      let year = temp.getFullYear();
      let dateOnly = year + "-" + month + "-" + day;
      let hour = temp.getHours();
      let minutes = temp.getMinutes();
      let amPm = hour < 12 ? "AM" : "PM";
      hour = hour % 12;
      hour = hour ? hour : 12;
      let time = `${hour}:${minutes < 10 ? "0" + minutes : minutes} ${amPm}`;
      return dateOnly + " " + time;
    },
    checkReserveMet(lastBid = 0, ReservePrice) {
      if (lastBid >= ReservePrice) return "Met";
      return "Not met";
    },

    //get Date differenct
    getDaysDifference(day1, day2) {
      //const date = moment(day1).format("YYYY-MM-DD");
      //const date2 = moment(day2).format("YYYY-MM-DD");
      const date = moment(day1);
      const date2 = moment(day2);
      const diffDays = moment(date).diff(moment(date2), "days");
      const diffHours = moment(date).diff(moment(date2), "hours");
      const diffMinutes = moment(date).diff(moment(date2), "minutes");

      let dayText = "Day";
      if (diffDays > 1) {
        dayText = "Days";
      }

      if (diffDays) {
        return diffDays + " " + dayText;
      } else if (diffHours) {
        return diffHours + "H ";
      } else if (diffMinutes) {
        return diffMinutes + "M";
      } else {
        return "NAN";
      }
    },

    checkAuctionStatus(status) {
      //console.log("current Status: ", status);
      let currentDate = new Date();
      let returnStatus;
      const { auctionStartDate, auctionEndDate } = this.data;
      //console.log("auctionStartDate: ", auctionStartDate);
      //console.log("auctionEndDate: ", auctionEndDate);
      if (
        currentDate >= new Date(auctionStartDate) &&
        currentDate <= new Date(auctionEndDate) &&
        status !== auctionStatus.PENDING_APPROVAL
      )
        returnStatus = "Live";
      if ([auctionStatus.PENDING_APPROVAL].includes(status))
        returnStatus = "Pending";
      if (
        [
          auctionStatus.COMPLETED_SOLD,
          auctionStatus.COMPLETED_RESERVED_NOT_MET,
        ].includes(status)
      )
        returnStatus = "Completed";
      return returnStatus;
    },

    formatOutcome(status) {
      switch (status) {
        case auctionStatus.LIVE:
          return "Live";
        case auctionStatus.LIVE_RESERVE_MET:
          return "Live - Reserve met";
        case auctionStatus.LIVE_RESERVE_NOT_MET:
          return "Live - Reserve not met";
        case auctionStatus.COMPLETED_SOLD:
          return "Completed - Sold";
        case auctionStatus.COMPLETED_RESERVED_NOT_MET:
          return "Completed - Reserve not met";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-container {
  display: flex;
  flex-wrap: wrap;
}

.status-btn {
  margin-right: 15px;
  margin-top: 10px;
}
</style>
